import * as PropTypes from "prop-types"
import React from "react"

import { Breadcrum, Type, PageLinks, BackBtn, SEO } from "../components/index";
import { getFirstTokenById } from '../utils/tokens'
import { tokenTypeType, pageLinkType, breadcrumType } from '../types'
import { getTokenName } from '../components/token/tokenName/tokenName'

class TokenPageTemplate extends React.PureComponent {
  render() {
    const {
      location: {
        state
      },
      pageContext: {
        token,
        token: {
          power,
          toughness,
          color,
          name,
          types,
          tokens
        },
        ...rest
      }
    } = this.props;
    const hasReferer = state && state.breadcrum;
    const featuredToken = (state && state.featuredTokenId) ? getFirstTokenById(tokens, state.featuredTokenId) : null;
    const pageName = (toughness && power) ? ` (${power}/${toughness})` : ``;
    const breadcrumPages = [{
      text: `${token.name}${pageName}`,
      active: true,
      to: `/token-list/${token.id}/`
    }];
    if (hasReferer) {
      breadcrumPages.unshift(...state.breadcrum)
    } else {
      breadcrumPages.unshift({
        text: 'A-Z Token List',
        to: '/token-list/',
      })
    };
    const tokenName = getTokenName({
      power: power,
      toughness: toughness,
      color: color,
      name: name,
      types: types
    })
    return (
      <>
        <SEO
          title={tokenName}
          description={`Magic: the Gathering (MTG) ${tokenName}.`}
          keywords={[name, tokenName]}
          pageUrl={`/token-list/${token.id}/`}
        />
        <Breadcrum pages={breadcrumPages} />
        <h1>{tokenName}</h1>
        <Type token={token} featuredToken={featuredToken} />
        { hasReferer ? <BackBtn /> : <PageLinks {...rest} /> }
      </>
    )
  }
}

TokenPageTemplate.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      breadcrum: breadcrumType,
      featuredTokenId: PropTypes.string
    }),
  }),
  pageContext: PropTypes.shape({
    token: PropTypes.shape({
      ...tokenTypeType
    }),
    ...pageLinkType,
  }).isRequired
}

export default TokenPageTemplate